/* src/Components/Cart/Cart.css */
.empty-cart-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .empty-cart-content {
    margin-top: 50px;
  }
  
  .empty-cart-content h3 {
    margin-bottom: 20px;
  }
  
  .empty-cart-content button {
    background-color: #F7444E;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .empty-cart-content button:hover {
    background-color: #dd1c26;
  }
  
  .bill-details-container {
    width: 100%;
    max-width: 400px; /* Adjust maximum width for responsiveness */
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adding a subtle shadow */
  }
  
  .bill-details-heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .bill-details-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .bill-details-row span {
    font-size: 16px;
  }
  
  .bill-details-total {
    font-weight: 600;
  }
  
  .bill-details-divider {
    margin: 20px 0;
  }
  
  @media (max-width: 600px) {
    .bill-details-container {
      max-width: 100%;
      box-shadow: none; /* Remove shadow on smaller screens if desired */
    }
  }
  